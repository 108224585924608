'use client';

import { Skeleton, SkeletonGroup, componentSpacing } from '@knapsack/toby';
import { PageLayout } from '@/core/layout/page-layout';
import {
  pageHero,
  pageHeroContent,
} from '@/core/site/components/page-hero/page-hero.css';

const DocumentSkeleton = () => {
  return (
    <PageLayout
      // skeletons should not be tracked or have meta tags
      trackInfo={undefined}
      metaTags={undefined}
      hero={
        <div className={pageHero({ size: 'small' })}>
          <div className={pageHeroContent}>
            <Skeleton style={{ minHeight: '200px' }} />
          </div>
        </div>
      }
    >
      <div>
        <SkeletonGroup
          className={componentSpacing({ marginBottom: 'xlarge' })}
          count={3}
          randomWidths
          skeletons={{ size: 'xsmall' }}
        />
        <SkeletonGroup
          className={componentSpacing({ marginBottom: 'xlarge' })}
          count={5}
          randomWidths
          skeletons={{ size: 'xsmall' }}
        />
        <SkeletonGroup
          className={componentSpacing({ marginBottom: 'xlarge' })}
          count={3}
          randomWidths
          skeletons={{ size: 'xsmall' }}
        />
        <SkeletonGroup
          className={componentSpacing({ marginBottom: 'xlarge' })}
          count={5}
          randomWidths
          skeletons={{ size: 'xsmall' }}
        />
        <SkeletonGroup
          className={componentSpacing({ marginBottom: 'xlarge' })}
          count={3}
          randomWidths
          skeletons={{ size: 'xsmall' }}
        />
      </div>
    </PageLayout>
  );
};

export default DocumentSkeleton;
